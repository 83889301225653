export const APP_VERSION = '1.0.1';
export const API_URL_COINCAP = 'https://api.coincap.io/v2';
export const API_URL_SOCKET = 'wss://ws.coincap.io';
export const APP_DIVIDER = '|';
export const APP_TITLE_PREFFIX = 'Crypto how much sir? Crypto currencies Live Prices, Live Charts, and Market Data';
export const APP_NAME = 'Crypto How Much, Sir?';
export const APP_MAIL = 'support@cryptohowmuch.com';
export const CHANGELLY_REF_ID = '0lg2oa7jfel00bwu'; //PAST YOUR CHANGELLY REFERAL ID
export const CHANGELLY_EXANGE_URL = 'https://changelly.com';
export const CHANGELLY_EXANGE_AMOUNT = 0.1;
export const COINS_LIMIT = 1000; // Max size 2000 per request
export const SYMBOL_EMPTY = '–';
